import { render, staticRenderFns } from "./Social.vue?vue&type=template&id=475c3236&scoped=true"
import script from "./Social.vue?vue&type=script&lang=js"
export * from "./Social.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475c3236",
  null
  
)

export default component.exports