import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    meta: {
      title: "Joanie's Kids",
    },
    component: () => import("../views/all-home-version/MinimalAgency.vue"),
  },
  // {
  //     path: '/main-demo',
  //     name: 'MainDemo',
  //     meta: {
  //         title: 'Joanie\'s Kids'
  //     },
  //     component: () =>
  //         import ('../views/all-home-version/MainDemo.vue')
  // },
  {
    path: "/freelancer",
    name: "Freelancer",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/Freelancer.vue"),
  },
  {
    path: "/agency",
    name: "Agency",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/Agency.vue"),
  },
  {
    path: "/minimal-agency",
    name: "MinimalAgency",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/MinimalAgency.vue"),
  },
  {
    path: "/creative-portfolio",
    name: "CreativePortfolio",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/CreativePortfolio.vue"),
  },
  {
    path: "/designer-portfolio",
    name: "DesignerPortfolio",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },

    component: () => import("../views/all-home-version/DesignerPortfolio.vue"),
  },
  {
    path: "/vertical-portfolio",
    name: "VerticalPortfolio",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/VerticalPortfolio.vue"),
  },
  {
    path: "/multiscroll-portfolio",
    name: "MultiScrollPortfolio",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () =>
      import("../views/all-home-version/MultiScrollPortfolio.vue"),
  },
  {
    path: "/parallax-home",
    name: "ParallaxHome",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/all-home-version/ParallaxHome.vue"),
  },
  {
    path: "/portfolio-details",
    name: "PortfolioDetails",
    meta: {
      title: "Joanie's Kids || Portfolio Details",
    },
    component: () => import("../views/PortfolioDetails.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Joanie's Kids || Contact",
    },
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    meta: {
      title: "Joanie's Kids || AboutUs",
    },
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/awards",
    name: "Awards",
    meta: {
      title: "Joanie's Kids || Awards",
    },
    component: () => import("../views/Awards.vue"),
  },
  {
    path: "/shop",
    name: "Blog",
    meta: {
      title: "Joanie's Kids || Blog",
    },
    component: () => import("../views/Blog.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Joanie's Kids || Portfolio",
    },
    component: () => import("../views/Portfolio.vue"),
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "Joanie's Kids || 404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
